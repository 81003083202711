const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Sign In",
          register: "Don't have an account? Register!",
          forgotPassword: "Forgot password?",
        },
      },
      ResetPassword: {
        title: "Reset Password",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Reset Password",
          login: "Back to login",
        },
        toasts: {
          success: "Password reset successfully!",
          info: "We have sent you a new password to your email!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
        messages: {
          inAttendance: {
            title: "In Service",
          },
          waiting: {
            title: "Waiting",
          },
          closed: {
            title: "Closed",
          },
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: {
            title: "Add Connection",
            whatsapp: "Add Waapy Engine",
            whatsappOfficial: "Add WhatsApp Official",
          },
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connecting",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          webVersion: "Web Version",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      connectionSelect: {
        inputLabel: "Select Connection",
        noConnection: "No Connection",
      },
      chatAi: {
        comingSoon: {
          title: "Coming Soon!",
          subtitle: "We are working hard to bring you this feature.",
          link: "Click here to see our progress.",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
          isBot: "Chat Bot",
          farewellMessage: "Farewell Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      whatsAppOfficialModal: {
        title: {
          add: "Add WhatsApp Official",
          edit: "Edit WhatsApp Official",
        },
        form: {
          name: "Name",
          default: "Default",
          token: "Token",
          farewellMessage: "Farewell Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp Official saved successfully.",
      },
      qrCode: {
        title: "Use Waapy Engine with you WhatsApp:",
        message: "Read QrCode to start the session",
      },
      message: {
        edited: "Edited",
        fileName: "File Name: ",
      },
      messageHistoryModal: {
        title: "Message History",
        close: "Close",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
          noContactSelected: "No contact selected!",
        },
        searchPlaceholder: "Search by name or phone number",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import WhatsApp contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage:
            "Do you want to import all WhatsApp contacts from the your phone?",
          bulkDeleteTitle: "Delete Selected Contacts",
          bulkDeleteMessage:
            "Are you sure? It cannot be reverted.\nWe'll delete all selected contacts.\nIf the contacts have a ticket, it will not be deleted.",
        },
        buttons: {
          import: "Import Ws Contacts",
          importExportFile: "Import/Export",
          add: "Add",
          bulkDelete: "Delete Selected Contacts",
          filter: "Filter",
          sortBy: "Sort by",
          sortByDate: "Latest",
          sortByNameAz: "Name: A to Z",
          sortByNameZa: "Name: Z to A",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          group: "Group",
          actions: "Actions",
          sendUsingWhatsapp: "Send using WhatsApp Official",
          sendUsingWaapy: "Send using Waapy Engine",
          label: {
            add: "Labels",
          },
        },
        importExportFile: {
          importByFileTab: "Import Contact By File",
          syncContactTab: "Sync Contact from Ws",
          exportContactTab: "Export Contact",
          message:
            "For Import Contact, the file support is .xls and .xlsx only. Your can download the Example format.\nJust only 1 file in 1 time, and limit every file is 100 contact only.",
          button: "Select Excel File",
          buttonLoading: "Importing...",
          finish: "DONE",
          exampleFile: "Example File",
          syncContact: {
            message:
              "Sync Contact from WhatsApp. Please select the connection before sync.",
            button: "Sync Contact Now",
            buttonLoading: "Syncing...",
          },
          exportContact: {
            message:
              "Export Contact, please select which type you want to export.",
            labels: "Labels",
            groupContacts: "Group / Contacts",
            groupOnly: "Group Only",
            contactOnly: "Contact Only",
            all: "All",
            button: "Export Contact",
          },
        },
      },
      contactFilterModal: {
        title: "Filters",
        labelFilter: "Labels",
        buttons: {
          apply: "Apply",
          clear: "Clear All",
          cancel: "Cancel",
        },
        showGroupContacts: "Show Groups or Contacts",
        showAll: "Show All",
        showContactOnly: "Show Contact Only",
        showGroupOnly: "Show Group Only",
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          files: "Files",
          noFiles: "No files",
          selectFiles: "Select files",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
        successAddImage: "Image for Quick Reply saved successfully.",
        successDeleteImage: "Image for Quick Reply deleted successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          whatsapp: "Default Connection",
          status: "Status",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      ticketsFilterSelect: {
        openFilter: "Filter",
        title: "Filter by",
        queues: "Queues",
        labels: "Labels",
        none: "No Label",
        save: "Save",
        cancel: "Cancel",
        selectAll: "Select All",
        unselectAll: "Unselect All",
        reset: "Reset",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      colorPicker: {
        generateColor: "Generate New Color",
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        connectionTitle: "Connection that is currently being used.",
        botTitle: "Bot that is currently being used.",
        bot: "Bot Active",
        buttons: {
          accept: "Accept",
        },
        closed: "Closed",
        label: {
          add: "Labels",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
        selectChannel: "Select channel",
      },
      layout: {
        notification: {
          dialog: {
            title: "Enable Notifications?",
            text: "Enabling notifications will allow you to receive important updates and notifications from our application.",
            button: "Enable Notifications",
            cancel: "Cancel",
            dontShowAgain: "Don't show again",
          },
          success: "Notifications enabled successfully.",
          error:
            "Notifications are not enabled. Please enable them in your browser settings.",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          artificialIntelligenceSettings: "ChatAI",
          queues: "Queues",
          administration: "Administration",
          users: "Users",
          settings: "Settings",
          permissions: "Permissions",
          reports: "Reports",
          labels: "Labels",
          templates: "Templates",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      labels: {
        title: "Labels",
        table: {
          no: "No",
          name: "Name",
          description: "Description",
          color: "Color",
          actions: "Actions",
        },
        buttons: {
          add: "Add label",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this label will still exist, but will not have any labels assigned.",
        },
      },
      labelModal: {
        title: {
          add: "Add label",
          edit: "Edit label",
        },
        form: {
          name: "Name",
          description: "Description",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      labelAssignContactModal: {
        title: "Assign Label to Contact",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      labelAssignTicketModal: {
        title: "Assign Label",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      labelSelect: {
        inputLabel: "Labels",
      },
      queues: {
        title: "Queues",
        table: {
          id: "ID",
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      templates: {
        title: "Templates",
        lastUpdate: "Last update:",
        buttons: {
          update: "Update Templates",
        },
        table: {
          id: "ID",
          name: "Name",
          approveStatus: "Approve Status",
          status: "Status",
          actions: "Actions",
        },
        toasts: {
          updated: "Templates updated successfully!",
          noOfficalConnection: "No Official WhatsApp Connected",
        },
      },
      timeAutoCloseSelect: {
        inputLabel: "Auto close ticket",
        options: {
          0: "General settings",
          8: "8 hours",
          16: "16 hours",
          24: "24 hours",
          32: "32 hours",
          40: "40 hours",
          48: "48 hours",
          72: "3 days",
          168: "7 days",
          336: "14 days",
          720: "1 month",
          4380: "half a year",
          8760: "1 year",
          9999: "Never",
        },
      },
      farewellMessageTemplateSelect: {
        inputLabel: "Farewell Message Templates",
        noTemplate: "No template",
      },
      messageInputTemplate: {
        inputLabel: "Message Input Template",
        noTemplate: "24 hours chat session has expired. Choose a template to send a message",
      },
      groupSelect: {
        inputLabel: "Groups Permission",
      },
      resourceSelect: {
        inputLabel: "Resources",
      },
      chatFlowsSelect: {
        inputLabel: "Chat Flows",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          haveFiles: "Have File",
          file: "File",
          noFile: "No File",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          status: "Status",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          security: {
            title: "Security Section",
          },
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          attachmentProtectionEnabled: {
            name: "Attachment Protection",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          enterTrigger: {
            name: "Send message on enter?",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          linkProtectionEnabled: {
            name: "Link Protection",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          maskLink: {
            name: "Mask Links",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          disableHyperlink: {
            name: "Disable Hyperlink",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          timeAutoCloseTicket: {
            name: "Auto close ticket",
            options: {
              8: "8 hours",
              16: "16 hours",
              24: "24 hours",
              32: "32 hours",
              40: "40 hours",
              48: "48 hours",
              72: "3 days",
              168: "7 days",
              336: "14 days",
              720: "1 month",
              4380: "half a year",
              8760: "1 year",
              9999: "Never",
            },
            note: "The ticket will be automatically closed after this time. Select 'Never' to never close the ticket.",
          },
          createNewTicketAfterHours: {
            name: "Create new ticket after",
            options: {
              0: "Immediately",
              4: "4 hours",
              8: "8 hours",
              12: "12 hours",
              16: "16 hours",
              20: "20 hours",
              24: "24 hours",
              48: "48 hours",
              72: "3 days",
              96: "6 days",
              120: "10 days",
            },
            note: "The ticket will be automatically create new ticket ID after this time. Select 'Immediately' to create the new ticket ID immediately.",
          },
          greetingMessageCount: {
            name: "Greeting message count",
            options: {
              1: "1 times",
              2: "2 times",
              3: "3 times",
              4: "4 times",
              5: "5 times",
              6: "6 times",
              7: "7 times",
              8: "8 times",
              9: "9 times",
              0: "Infinity",
            },
            note: "The number of times the greeting message will be sent. Select 'Infinity' to keep sending the message.",
          },
          groupCapture: {
            name: "Group capture",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          notifications: {
            name: "Notifications",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          version: "Interface Version",
          versionBackend: "Engine Version",
          keywordsIgnore: {
            name: "Ignore keywords",
            note: "The keywords will be ignored when sending a message. Use commas to separate multiple keywords.",
            button: "Save Keywords",
          },
        },
      },
      permissions: {
        title: "Permissions",
        confirmationModal: {
          deleteTitle: "Delete group",
          deleteMessage:
            "This action cannot be undone. Please confirm the group deletion.",
        },
        buttons: {
          addGroup: "Add new group permission",
        },
        toasts: {
          deleted: "Group permission deleted successfully.",
        },
      },
      reports: {
        title: "Reports",
        tableHeaders: {
          chatId: "Chat ID",
          createdAt: "Datetime by progress",
          progress: "Progress",
          user: "User Name",
        },
        noData: "No data",
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
            addNote: "Note",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        placeholderClosedWhatsappOfficial:
          "This ticket can't be reopen after resolved.",
        signMessage: "Sign",
        botMessage: "Ticket handling by bot",
        placeholder: "Type a message",
      },
      contactDrawer: {
        header: "Contact details",
        labels: "Labels",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      noteDrawer: {
        header: "Note for ticket",
        buttons: {
          save: "Save",
          cancel: "Cancel",
        },
        addNote: "Add note",
        createdAt: "Created at",
        createdBy: "Created by",
        noTitle: "Untitled",
        deleteNoteSuccess: "Note deleted successfully.",
        noContent: "No content",
        deleteNote: "Delete Note",
        deleteNoteConfirm: "Are you sure you want to delete this note?",
      },
      noteModal: {
        title: {
          add: "Add note",
          edit: "Edit note",
        },
        form: {
          title: "Title",
          details: "Details",
        },
        buttons: {
          okEdit: "Save",
          okAdd: "Add",
          cancel: "Cancel",
        },
        success: "Note saved successfully.",
      },
      permissionModal: {
        title: {
          add: "Add permission group",
          edit: "Edit permission group",
        },
        form: {
          resource: "Resource",
          name: "Group Name",
        },
        buttons: {
          okEdit: "Save",
          okAdd: "Add",
          cancel: "Cancel",
        },
        success: "Permission Group saved successfully.",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        history: "History",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_SENDING_MESSAGE_TEMPLATE:
          "Error sending message template. Check your template format because have dynamic fields.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_SHORTCUT_DUPLICATED: "Shortcut already exists.",
        ERR_NO_SETTING_FOUND: "No setting found.",
        ERR_NO_CONTACT_FOUND: "No contact found.",
        ERR_CONTACT_HAS_TICKETS: "This contact has tickets.",
        ERR_NO_TICKET_FOUND: "No ticket found.",
        ERR_NO_USER_FOUND: "No user found.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_LABEL_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_LABEL_NAME_ALREADY_EXISTS:
          "This name is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
        ERR_USER_DISABLED: "User is disabled.",
        ERR_USER_NOT_ACTIVE: "User is not active.",
        ERR_NO_ACCESS_NOTE: "You don't have access to this note.",
        ERR_CANT_CHANGE_CHANNEL: "You can't change this connection channel.",
        ERR_SENDING_WHATSAPP_OFFICIAL_MSG:
          "Error sending WhatsApp official message.",
        ERR_MEDIA_TYPE_NOT_SUPPORTED_WHATSAPP_OFFICIAL:
          "Media type not supported.",
        ERR_SENDING_WHATSAPP_OFFICIAL_MSG_AFTER_24H:
          "Can't send WhatsApp official message when last message was more than 24 hours.",
      },
      CREATE_TICKET: "Create Ticket",
      PENDING_TICKET: "Pending Ticket",
      OPEN_TICKET: "Open Ticket",
      DELETE_TICKET: "Delete Ticket",
      CLOSE_TICKET: "Close Ticket",
      TRANSFER_TICKET: "Transfer Ticket",
      UPDATE_QUEUE: "In Queue",
      UPDATE_WHATSAPP_CONNECTION: "Change WhatsApp Connection",
    },
  },
};

export { messages };
